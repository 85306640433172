import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"

export default () => {
  return (
    <Layout>
      <HeadMeta pageTitle="Request a phrase" />
      <PageHeader>Request a phrase</PageHeader>
      <Breadcrumb
        route={[
          { name: "Multilingual library signage", href: "/" },
          { name: "Request a phrase", href: "/" },
        ]}
      />
      <p>
        Please send requests for new translations to
        Public Library Services.
      </p>
      <dl>
        <dt>Address:</dt>
        <dd>
          Public Library Services<br />
          State Library of New South Wales<br />
          1 Shakespeare Place<br />
          Sydney NSW 2000<br />
          Australia
        </dd>
        <dt>Fax:</dt>
        <dd><a href="tel:+61292731248">(02) 9273 1248</a></dd>
        <dt>Email:</dt>
        <dd><a href="mailto:pls.library@sl.nsw.gov.au">pls.library@sl.nsw.gov.au</a></dd>
      </dl>
    </Layout>
  );
}
